document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector("body").classList.contains("home")) {
    // Récupère les collections
    let collections = $(".collection-toggler");

    // Récupère les images
    let images = $(".collection-img");

    let collections_array = Array.from(collections);
    let images_array = Array.from(images);

    let activeImage = images_array.filter(image => image.getAttribute("data-id") == collections_array[0].getAttribute("data-id") );

    // Affiche la première image
    activeImage[0].classList.add("fade-in-image");

    // Affiche l'image correspondante à la collection cliquée
    collections_array.map((collection) => {
      collection.addEventListener("click", () => {
        images_array.map((image) => {
          if(collection.getAttribute("data-id") == image.getAttribute("data-id")) {
            $(".fade-in-image").removeClass("fade-in-image");
            image.classList.add("fade-in-image");
          }
        });
      });
    });

  }
});
