import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector("body").classList.contains("home")) {
    gsap.to('.boat-img', {
      xPercent: () => {
        if(window.innerWidth >= 1399) {
          return 200;
        } else if(window.innerWidth >= 992) {
          return 100;
        }
      },
      scrollTrigger: {
        trigger: ".bloc-black-banner",
        scrub: 5,
        start: "top 60%",
        markers: false,
      },
    });
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector("body").classList.contains("notre-histoire")) {
    let sections = gsap.utils.toArray(".panel");

    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".horizontal-container",
        pin: true,
        scrub: 1,
        snap: 1 / (sections.length - 1),
        end: () => "+=" + document.querySelector(".horizontal-container").offsetWidth,
      },
    });
  }
});
