var $hamburger = $(".hamburger");
var $header = $(".header");
var $logo = $(".logo-desktop");
var $whiteLogo = $(".logo-desktop-white");

$hamburger.on("click", function() {
  $hamburger.toggleClass("is-active");
  $header.toggleClass("has-background is-contained is-fixed");
  $logo.toggleClass("is-hidden");
  $whiteLogo.toggleClass("is-visible");
});
