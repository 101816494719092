/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider */
var carousel_slide = new Swiper('.carousel-swiper', {
  slidesPerView: 1.5,
  spaceBetween: 20,
  centeredSlides: false,
  navigation: {
    nextEl: '.carousel-next',
    prevEl: '.carousel-prev',
  },
});

/* Actus */
var actus_slide = new Swiper('.actus-swiper', {
  slidesPerView: "auto",
  spaceBetween: 20,
  centeredSlides: false,
  navigation: {
    nextEl: '.actus-next',
    prevEl: '.actus-prev',
  },
});

/* Values */
var values_slide = new Swiper('.values-swiper', {
  slidesPerView: "auto",
  centeredSlides: false,
  loop: "true",
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },

  on: {
    init: function() {
      // Récupère la slide active
      let activeSlide = $(".values-slide.swiper-slide-active");
      let activeSlideIndex = activeSlide.data("id");

      // Crée un tableau d'images
      let images = $(".header-img");
      let imagesArr= Array.from(images);

      // Récupère l'image correspondante à la slide active
      let activeImage = imagesArr.filter(image => image.getAttribute("data-id") == activeSlideIndex );

      // Ajoute le "fade-in effect" à l'image associée
      if(activeImage[0].getAttribute("data-id") == activeSlideIndex) {
        activeImage[0].classList.add("fade-in-image");
      }
    },

    slideChangeTransitionStart: function() {
      // Récupère la slide active
      let activeSlide = $(".values-slide.swiper-slide-active");
      let activeSlideIndex = activeSlide.data("id");

      // Crée un tableau d'images
      let images = $(".header-img");
      let imagesArr= Array.from(images);

      // Récupère l'image correspondante à la slide active
      let activeImage = imagesArr.filter(image => image.getAttribute("data-id") == activeSlideIndex );

      // Ajoute le "fade-in effect" à l'image associée
      if(activeImage[0].getAttribute("data-id") == activeSlideIndex) {
        $(".fade-in-image")[0].classList.remove("fade-in-image");
        activeImage[0].classList.add("fade-in-image");
      }
    },
  },
});

/* eslint-enable no-unused-vars */
